/*
 * Panorama Studios
 */

// Disable inital GMap3 loading
$.gmap3(false);

$(document).ready(function () {
	// Typed effect
	$(".typed").typed({
		strings: [$('.hero h1').text()],
		typeSpeed: 10
	});

	// Parallax
	$('.parallax').each(function () {
		var element = $(this);
		var img = element.find('img')[0];

		element.parallax({
			speed: 0.4,
			imageSrc: img.src,
			naturalWidth: 1920,
			naturalHeight: 1280
		});
		$(img).remove();
	});

	// Header affix
	$('header').each(function () {
		var element = $(this);
		var headroom = element.headroom({
			tolerance: {
				down: 0,
				up: 20
			},
			offset: 205
		});
		if (element.hasClass('headroom')) {
			$(document.body).addClass('headroom--enabled');
		}
	});

	// Masonry
	$('.masonry').masonry({
		columnWidth: 'li',
		itemSelector: 'li'
	});

	// Slideshow
	$('.slideshow--text').each(function () {
		var element = $(this);
		var cssClass = element.attr('class');
		if (element.children('li').length > 1) {

			var slider = element.lightSlider({
				slideMargin: 0,
				mode: 'fade',
				adaptiveHeight: true,
				loop: true,
				auto: true,
				speed: 1000,
				pause: 7000,
				pager: true,
				controls: false,
				addClass: cssClass,
				item: 1,
			});

			element.addClass('active');
		}
	});

	$('.slideshow--hero').each(function () {
		var element = $(this);
		var cssClass = element.attr('class');
		if (element.children('li').length > 1) {

			var slider = element.lightSlider({
				slideMargin: 0,
				mode: 'fade',
				adaptiveHeight: true,
				loop: true,
				auto: true,
				speed: 1000,
				pause: 7000,
				pager: true,
				controls: false,
				addClass: cssClass,
				item: 1,
			});

			element.addClass('active');
		}
	});

	// Slider
	$('.slider--case').each(function () {
		var element = $(this),
			cssClass = element.attr('class');

		if (element.children('.slide').length > 1) {
			element.lightSlider({
				slideMargin: 0,
				loop: true,
				auto: true,
				speed: 1500,
				pause: 6000,
				pager: true,
				controls: true,
				item: 1,
				slideMove: 0,
				addClass: cssClass
			});
		}
	});

	$('.slider--portfolio').each(function () {
		var element = $(this),
			cssClass = element.attr('class');

		if (element.children('.slide').length > 1) {
			element.lightSlider({
				mode: 'fade',
				slideMargin: 0,
				loop: true,
				auto: true,
				speed: 1000,
				pause: 6000,
				pager: true,
				controls: true,
				item: 1,
				slideMove: 1,
				addClass: cssClass
			});
		}
	});
	
	$('.slider--disciplines').each(function () {
		var element = $(this),
			cssClass = element.attr('class');

		if (element.children('.slide').length > 1) {
			element.lightSlider({
				slideMargin: 0,
				loop: true,
				auto: false,
				speed: 1000,
				pause: 4000,
				pager: false,
				controls: true,
				item: 3,
				slideMove: 1,
				addClass: cssClass
			});
		}
	});

	// Smooth scroll
	$('a[href^="#"]').click(function () {
		var target = $(this.hash);
		if (!target.length) {
			target: $('[name=' + this.hash.slice(1) + ']');
		}
		if (target.length) {
			$('html, body').animate({
				scrollTop: target.offset().top - 85
			}, 500);

			return false;
		}
	});

	// Route form
	$('.form.route').each(function () {
		var element = $(this);
		var button = element.find('button');
		var destinationAddress = element.find('input[type=text]');
		var destination = element.find('#To');

		// Route on button click
		button.click(function (e) {
			var route = {
				saddr: destinationAddress.val(),
				daddr: destination.val()
			};

			window.open('https://maps.google.com/?' + $.param(route), '_blank');

			e.preventDefault();
		});
	});

	// Google maps
	var maps = $('.google-maps');
	var iconUrl = 'http://demo.panoramastudios.nl/marker-icon.png';

	console.log(iconUrl)
	if (maps.length) {

		// Load Google Maps API once
		$.ajaxSetup({
			cache: true
		});

		$.getScript('https://maps.google.com/maps/api/js?sensor=false', function () {

			label = maps.children('figcaption');
			maps.gmap3({
				center: [52.00499, 4.209832],
				zoom: 14,
				mapTypeControl: false,
				navigationControl: true,
				scrollwheel: false,
				draggable: false
			})
			//.marker([{
			//	address: "Warmoezenierstraat 5, 2671 ZP Naaldwijk",
			//	options: {
			//		icon:
			//			new google.maps.MarkerImage(iconUrl,   //icon url
			//			new google.maps.Size(232, 322),    //sets the icon size
			//			new google.maps.Point(0, 0),    //sets the origin point of the icon
			//			new google.maps.Point(218, 283)),    //sets the anchor point for the icon
			//	}
			//}
			.marker([{}]);
			maps.append(label);
			maps.addClass('activated');
		});
	}

	// Change target for external sites
	var getDomainName = function (hostName) {
		return hostName.substring(hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1);
	};
	$('a:not([target])').filter(function () {
		var link = $(this);

		if (link.filter('[rel~="external"]').length) return true; // Link rel attribute contains 'external'

		var href = link.attr('href');
		if (new RegExp('\.pdf$', 'i').test(href)) return true; // Link to PDF file

		if (new RegExp('^https?://', 'i').test(href)) {
			var linkHostName = getDomainName(href.substring(href.indexOf('/') + 2, href.indexOf('/', 8)));
			if (!new RegExp('(^|\.)' + getDomainName(document.location.hostname) + '$', 'i').test(linkHostName)) {
				// Link is to different domain name
				return true;
		}
	}

		return false;
	}).attr('target', '_blank');
});